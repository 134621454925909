.title {
  color: $color-h2;
  font-weight: 700;
}

.title-row {
  @include row-left-center;
  margin-bottom: 10px;
  gap: 40px;
}

.price-slider {
  width: 30%;
  min-width: 340px;
}

.basic-data-input {
  width: 47%;
}

.consumption-input {
  width: 30%;
}

.checkboxes {
  width: 40%;

  .checkbox-label {
    min-width: 100px;
  }
}

.price-inflation-row {
  @include row-spacebetween-center;
  gap: 25px;
  margin-bottom: 10px;
}

.lastRowTitle {
  color: $color-h2;
  padding-bottom: 5px;
}

.upload-image-button {
  width: 170px;
}

.cross-button {
  margin-left: 10px;
  background: transparent;
  border: none;
  cursor: pointer;
  font-size: 16px;
}

.selected-image {
  width: auto;
  height: 200px;
  border: 1px solid #ddd;
  padding: 5px;
  margin-top: 20px;
}

.image-error-message {
  color: red;
  margin-top: 10px;
}
