input[type='number']::-webkit-inner-spin-button,
input[type='number']::-webkit-outer-spin-button {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  margin: 0;
}

.input-text {
  width: 340px;
  box-sizing: border-box;
  position: relative;

  &.full-width {
    width: 100%;
  }

  .title {
    @include font-size-14;
    font-weight: 400;
    color: $color-input-title;
    line-height: normal;
    padding-bottom: 3px;
    text-overflow: ellipsis;
    text-wrap: nowrap;
    width: 100%;
    overflow: hidden;

    &.red {
      color: $color-error;
    }
  }

  .inner {
    @include row-spacebetween-center;
    width: 100%;
    border: 1px solid $color-input-border;
    height: 40px;
    background-color: $white;
    box-sizing: border-box;

    &.disabled {
      background-color: $color-input-disable;
    }

    .inner-input {
      padding: 0 14px;
      width: 100%;
      position: relative;

      @include row-left-center;
      gap: 15px;

      .left-icon-wrapper {
        width: 50px;
        height: 30px;
        border-radius: 5px;
        overflow: hidden;

        .left-icon {
          width: auto;
          height: auto;
          object-fit: contain;
        }
      }

      .right-icon {
        position: absolute;
        right: 10px;
        width: 14px;
        height: 8px;
      }

      .suffix {
        @include font-size-14;
        position: absolute;
        font-weight: 400;
        right: 7px;
      }

      input {
        border: 0;
        height: 38px;
        width: 100%;
        line-height: 40px;
        @include font-size-14;
        font-weight: 400;
        color: $black;
        background: none;
        padding: 0;

        @include placeholder {
          @include font-size-14;
          color: $black;
        }

        &:focus {
          outline: none;
        }
      }

      .text {
        min-width: 30px;
        min-height: 10px;
      }
    }

    .unit {
      @include font-size-14;
      font-weight: 400;
      padding-right: 8px;
      width: 40px;
    }

    &:focus {
      border: 1px solid $color-input-focus;
    }
  }

  .error {
    @include font-size-12;
    font-weight: 400;
    color: $color-error;
    padding-top: 1px;
  }

  .hint {
    @include font-size-12;
    font-weight: 400;
    color: $color-input-title;
    padding-top: 1px;
  }

  .input-bottom {
    height: 15px;
  }
}

// CheckboxComponent.module.scss
.checkbox-container {
  @include column;

  .checkbox-title {
    @include font-size-14;
    font-weight: 400;
    color: $color-input-title;
    line-height: normal;

    &.red {
      color: $color-error;
    }
  }

  .checkbox-group {
    @include row-left-center;
    flex-wrap: wrap;

    .checkbox-label {
      @include row-left-center;
      @include font-size-14;
      font-weight: 400;
      user-select: none;
      min-width: 200px;
      margin-top: 10px;
      margin-bottom: 10px;

      .checkbox-input {
        margin-right: 8px;
      }
    }
  }

  .hint {
    @include font-size-12;
    font-weight: 400;
    color: $color-input-title;
    padding-top: 1px;
  }
}

//
//.input-date {
//    position: relative;
//
//    .inner {
//        @include row-left-center;
//        background: none;
//        padding-right: 0px;
//        .container-inner {
//            flex: 1;
//        }
//        .btn-icon {
//            height: 44px;
//            width: 44px;
//            background-image: url('../assets/icon-calendar.png');
//            background-position: center center;
//            background-repeat: no-repeat;
//        }
//    }
//
//    .picker {
//        position: absolute;
//        right: 0px;
//    }
//}
//
//.form-files {
//    margin-top: 20px;
//    width: 100%;
//
//    .title {
//        @include font-size-17;
//        font-weight: 500;
//    }
//
//    .dropzone {
//        @include column-center-center;
//        width: 100%;
//        border: 1px dashed $color-filezone-border;
//        border-radius: 5px;
//        padding: 20px 0px;
//        margin-top: 10px;
//
//        .text {
//            @include font-size-14;
//            font-weight: 500;
//            color: $color-filezone-text;
//            text-align: center;
//        }
//
//        .btn {
//            @include font-size-14;
//            font-weight: 500;
//            display: inline-block;
//            color: $color-filezone-text;
//            text-align: center;
//            border: 1px solid $color-filezone-button-border;
//            border-radius: 5px;
//            padding: 9px 6px;
//            margin-top: 16px;
//            cursor: pointer;
//
//            &:hover {
//                background-color: $color-hover-btn-white;
//                transition: all 0.3s;
//                -webkit-transition: all 0.3s;
//            }
//        }
//    }
//    .list {
//        width: 100%;
//
//        .list-item {
//            @include row-spacebetween-center;
//
//            .name {
//                @include font-size-16;
//                font-weight: 300;
//                color: $orange;
//            }
//            .btnRemove {
//                @include touchArea;
//                background: url('../assets/icon-trash.png') center center no-repeat;
//            }
//        }
//    }
//}
//
//.form-select {
//    width: 100%;
//    box-sizing: border-box;
//    margin-top: 20px;
//    .inner {
//        width: 100%;
//        border-bottom: 1px solid $color-line-bg;
//        height: auto;
//        background: none;
//        padding-right: 0px;
//        box-sizing: border-box;
//
//        &.no-border {
//            border-bottom: 0px;
//        }
//
//        .title {
//            @include font-size-14;
//            font-weight: 500;
//
//            &.red {
//                color: $color-error;
//            }
//        }
//
//        .selectcomp__control {
//            @include row-spacebetween-center;
//            border: none !important;
//            background: none;
//            box-shadow: none !important;
//
//            .selectcomp__indicators {
//                border: none !important;
//                background: none;
//                display: inline-block;
//                padding: 0px;
//
//                .selectcomp__indicator {
//                    padding: 0px;
//
//                    .btn-drop {
//                        width: 44px;
//                        height: 44px;
//                        background: url('../assets/icon-select.png') no-repeat;
//                        cursor: pointer;
//                    }
//
//                    .btn-drop-async {
//                        width: 44px;
//                        height: 44px;
//                        background: url('../assets/icon-select-async.png') no-repeat center;
//                        cursor: pointer;
//                    }
//                }
//            }
//        }
//    }
//    &.form-select-blue {
//        .inner {
//            border-bottom: 2px solid $color-inptut-blue;
//        }
//    }
//
//    .error {
//        @include font-size-12;
//        font-weight: 500;
//        color: $color-error;
//        padding-top: 2px;
//    }
//
//    .selectcomp__menu > {
//        .selectcomp__menu-list > {
//            .selectcomp__option {
//                &.selectcomp__option--is-focused {
//                    background-color: $color-select-hover;
//                }
//                &.selectcomp__option--is-selected {
//                    background-color: $color-select-selected;
//                    color: $white;
//                }
//                &:hover {
//                    background-color: $color-select-hover;
//                    transition: all 0.3s;
//                    -webkit-transition: all 0.3s;
//                }
//            }
//        }
//    }
//}
//
//.form-yesno {
//    width: 67px;
//    min-width: 67px;
//    height: 30px;
//    box-sizing: border-box;
//    cursor: pointer;
//
//    .inner {
//        @include row-spacebetween-center;
//        width: 100%;
//        height: 100%;
//        background-color: $color-yesno-yes;
//        border: 2px solid $color-yesno-yes;
//        border-radius: 60px;
//
//        .circle {
//            box-sizing: border-box;
//            height: 26px;
//            width: 26px;
//            border-radius: 13px;
//            background-color: $white;
//        }
//
//        .text {
//            @include font-size-12;
//            color: $white;
//            font-weight: 700;
//            text-align: center;
//            width: 33px;
//        }
//
//        &.inner-no {
//            background-color: $color-yesno-no;
//            border: 2px solid $color-yesno-no;
//        }
//    }
//    &.not-valid {
//        .inner {
//            border: 2px solid $color-yesno-notvalid;
//        }
//    }
//}

.slider-container {
  @include column;
  gap: 10px;
  min-width: 340px;
  gap: 10px;
  padding-left: 8px;

  .slider-label {
    @include font-size-14;
    color: $black;
    font-weight: 400;
    color: $color-button-border;
  }

  .slider-row {
    @include row-spacebetween-center;
    column-gap: 14px;

    .slider {
      display: flex;
      width: 70%;
      margin-left: 5px;
    }

    .slider-input {
      width: 60px;
    }
  }
}

.select {
  position: relative;
  min-width: 200px;

  &.full-width {
    width: 100%;
  }

  .select-input-wrapper {
    position: relative;
    @include row-left-center;
    gap: 15px;

    .select-arrow {
      position: absolute;
      width: 19px;
      height: 16px;
      right: 10px;
      top: 65%;
      transform: translateY(-50%);
      pointer-events: none;
    }
  }

  .select-dropdown {
    position: absolute;
    top: 58px;
    left: 0;
    right: 0;
    z-index: 10;
    border: 1px solid $color-input-border;
    background-color: $white;
    list-style-type: none;
    padding: 0;
    margin: 0;
    max-height: 250px;
    overflow-y: auto;
    overflow-x: hidden;
    box-sizing: content-box;

    scrollbar-width: thin; /* Firefox */

    .select-item {
      @include row-left-center;
      gap: 15px;
      padding: 10px 14px;

      .option-image-wrapper {
        width: 50px;
        height: 30px;
        border-radius: 5px;
        overflow: hidden;

        .option-image {
          width: auto;
          height: auto;
          object-fit: contain;
        }
      }

      cursor: pointer;

      &:hover,
      &.active {
        background-color: $color-bg;
      }
    }
  }
}

.checkbox-with-input {
  @include column;
  min-width: 200px;
  margin-top: 10px;
  margin-bottom: 10px;

  .checkbox-label {
    @include row-left-center;
    @include font-size-14;
    font-weight: 400;
    user-select: none;

    .checkbox-input {
      margin-right: 8px;
    }
  }

  .option-inputs-row {
    @include row-left-center;
    gap: 10px;

    .option-input {
      width: 40%;
    }
  }
}
